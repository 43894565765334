<template>
  <div class="filter-dropdown-comp">
    <van-dropdown-menu class="label-small">
      <van-dropdown-item
        :title="item.label"
        v-for="(item, index) in filtersCopy"
        :key="item.id"
        :ref="item.id"
      >
        <template v-if="item.id === 'date'">
          <van-cell center @click="openCalendar" :title="dateTime">
            <div>
              <van-icon
                name="close"
                style="font-size:35px;margin-right:10px"
                @click.stop="closeDate(index)"
              />
              <van-icon
                slot="right-icon"
                name="arrow"
                style="line-height: inherit;"
              />
            </div>
          </van-cell>
        </template>
        <template v-else>
          <van-cell
            center
            :class="{ 'filter-dropdown__option--active': isSelect(i, index) }"
            :title="op.text"
            @click.native="select(op, i, index)"
            v-for="(op, i) in item.options"
            :key="op.value + i"
          >
            <van-icon
              slot="right-icon"
              name="success"
              v-if="isSelect(i, index)"
              style="line-height: inherit;"
            />
          </van-cell>
        </template>
      </van-dropdown-item>
    </van-dropdown-menu>
    <van-calendar v-model="calendarVisible" type="range" @confirm="onConfirm" />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  name: 'filter-dropdown',
  props: {
    filters: Array,
    selectDate: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      calendarVisible: false,
      date: '',
      filtersCopy: [],
      selected: []
    }
  },
  computed: {
    dateTime() {
      return this.date || '请选择'
    }
  },
  methods: {
    ...mapMutations('oa', ['setFilters']),
    // 选中时触发
    select(option, index, outerIndex) {
      // 设置激活的选项下标
      this.selected[outerIndex] = index
      // 改变选中的菜单文本
      this.filtersCopy = this.filtersCopy.map((item, i) => {
        if (i === outerIndex) {
          if (item.label === option.text) {
            this.selected[outerIndex] = null
            if (item.noSelect) {
              return {
                ...item,
                label: item.noSelect
              }
            } else {
              if (item.twoClick) {
                return {
                  ...item,
                  label: this.filters[outerIndex].label
                }
              } else {
                return {
                  ...item,
                  label: option.text
                }
              }
            }
          } else {
            return {
              ...item,
              label: option.text
            }
          }
        } else {
          return item
        }
      })
      // 关闭下拉
      this.$nextTick(() => {
        this.$refs[this.filtersCopy[outerIndex].id][0].toggle()
      })
      // 传递事件
      const payload = {
        option,
        category: this.filtersCopy[outerIndex]
      }
      // 排除日期选择器
      if (payload.category.id !== 'date') {
        this.$emit('select', payload)
      }
    },
    // 是否选中 --class
    isSelect(index, outerIndex) {
      if (this.selected[outerIndex] === null) {
        return false
      } else {
        return this.selected[outerIndex] === index
      }
    },
    // 打开 #日期选择器#
    openCalendar() {
      this.calendarVisible = true
    },
    // 关闭 #日期选择器#
    closeCalendar() {
      this.calendarVisible = false
    },
    // 格式化日期
    formatDate(date) {
      let Date = ''
      const Y = date.getFullYear()
      let M = date.getMonth() + 1
      if (M < 10) {
        M = '0' + M
      }
      let D = date.getDate()
      if (D < 10) {
        D = '0' + D
      }
      Date = Y + '-' + M + '-' + D + ''
      return Date
    },
    // #日期选择器# 确认触发
    onConfirm(date) {
      const [start, end] = date
      this.closeCalendar()
      this.date = `${this.formatDate(start)} - ${this.formatDate(end)}`
      this.$emit('dateChange', [this.formatDate(start), this.formatDate(end)])
    },

    closeDate(index) {
      // 关闭下拉
      this.$nextTick(() => {
        this.$refs[this.filtersCopy[index].id][0].toggle()
      })
      this.closeCalendar()
      this.date = ''
      this.$emit('dateChange', ['', ''])
    }
  },
  created() {
    if (this.selectDate !== '-') {
      this.date = this.selectDate
    }
    this.filtersCopy = this.filters
    this.selected = this.filters.map(item => null)
    this.setFilters(null)
  },
  mounted() {}
}
</script>

<style lang="stylus">
.filter-dropdown-comp
  & .filter-dropdown__option--active
    color #1989fa
  & .van-dropdown-menu.label-small
    & .van-dropdown-menu__title
      font-size 14px
    & .van-dropdown-menu__bar
      width 100%
</style>
