var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "filter-dropdown-comp" },
    [
      _c(
        "van-dropdown-menu",
        { staticClass: "label-small" },
        _vm._l(_vm.filtersCopy, function (item, index) {
          return _c(
            "van-dropdown-item",
            {
              key: item.id,
              ref: item.id,
              refInFor: true,
              attrs: { title: item.label },
            },
            [
              item.id === "date"
                ? [
                    _c(
                      "van-cell",
                      {
                        attrs: { center: "", title: _vm.dateTime },
                        on: { click: _vm.openCalendar },
                      },
                      [
                        _c(
                          "div",
                          [
                            _c("van-icon", {
                              staticStyle: {
                                "font-size": "35px",
                                "margin-right": "10px",
                              },
                              attrs: { name: "close" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.closeDate(index)
                                },
                              },
                            }),
                            _c("van-icon", {
                              staticStyle: { "line-height": "inherit" },
                              attrs: { slot: "right-icon", name: "arrow" },
                              slot: "right-icon",
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                : _vm._l(item.options, function (op, i) {
                    return _c(
                      "van-cell",
                      {
                        key: op.value + i,
                        class: {
                          "filter-dropdown__option--active": _vm.isSelect(
                            i,
                            index
                          ),
                        },
                        attrs: { center: "", title: op.text },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.select(op, i, index)
                          },
                        },
                      },
                      [
                        _vm.isSelect(i, index)
                          ? _c("van-icon", {
                              staticStyle: { "line-height": "inherit" },
                              attrs: { slot: "right-icon", name: "success" },
                              slot: "right-icon",
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
            ],
            2
          )
        }),
        1
      ),
      _c("van-calendar", {
        attrs: { type: "range" },
        on: { confirm: _vm.onConfirm },
        model: {
          value: _vm.calendarVisible,
          callback: function ($$v) {
            _vm.calendarVisible = $$v
          },
          expression: "calendarVisible",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }